<template>
    <button
        v-if="Object.keys(parent_match).length && setName && parent_match.guid !== activeNoMatch"
        class="flex items-center space-x-2 p-2 border rounded bg-gray-200 hover:bg-white dark:hover:bg-gray-700 duration-300"
        :title="$t('product.select', $store.state.locale)"
        @click.prevent="selectProduct">
        <div 
            class="h-4 w-4 min-w-4 rounded-full duration-300"
            :class="getSelected ? 'bg-light-green-500' : 'bg-gray-300'"></div>
            <div class="flex flex-col space-y-1 w-full">
                <div class="w-full flex items-center justify-between space-x-3"> 
                    <span class="text-left dark:text-gray-200 text-lg font-bold">
                        {{ setName  }} 
                    </span>
                    <span
                        v-if="productIsFromGateway" 
                        class="px-1 py-0.5 rounded text-sm border border-blue-500">
                       {{ productIsFromGateway }}
                    </span>
                </div>
                <div class="flex flex-col space-y-1">
                    <!--<span class="text-left text-sm text-gray-400">{{ parent_match.formulator_name ? parent_match.formulator_name : '-' }}</span>-->
                    <p class="text-left text-sm text-gray-400">
                        <span class="text-gray-900">
                        {{$t('productTable.cols.formulator', $store.state.locale)}}: 
                        </span>
                        {{ setFormulator }}
                    </p>
                    <p class="text-left text-sm text-gray-400">
                        <span class="text-gray-900">
                        Id: 
                        </span>
                        <span class="text-left text-sm text-gray-400">{{ parent_match.id ? parent_match.id : parent_match.ProductID }}</span>
                    </p>
                    <div v-if="certifications.length" class="flex flex-col items-start text-left">
                        <span class="text-left text-sm text-gray-900">
                            {{ $t('matchModal.certifications', $store.state.locale) }}:
                        </span>
                        <p 
                            v-for="(c, i) in certifications" :key="i"
                            class="text-left text-sm text-gray-400 ml-2">
                            <span v-if="c.certification">
                                {{ c.certification }} / {{ c.certificationResult }}
                            </span>
                        </p>
                    </div>
                    
                </div>
            </div>
    </button>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { ref, watch, onMounted } from 'vue';
export default
{
    props:{ parent_match: {required:true}, active_match: {required: true}, activeNoMatch:{required:false, default: ''} },
    emits:['setActiveMatch'],
    setup(props, {emit})
    {
        /**
         *
         * Data
         *
         */
        const store = useStore();
        const active = ref(props.active_match);
        const certifications = ref([]);

        /**
         *
         * Watch
         *
         */
        watch( 
            () => props.active_match,
            () => 
            {
                active.value = props.active_match;
            }
        );

        /**
         *
         * Computed
         *
         */
        const getSelected = computed( () => 
        {
            if(active.value && props.parent_match.ProductID)
            {
                return active.value.id === props.parent_match.ProductID
            }
            else if(active.value && props.parent_match.id)
            {
                return active.value.id === props.parent_match.id
            }
            return false;
            
        });
        const setName = computed (() => 
        {
            if(props.parent_match.productName)
            {
                return props.parent_match.productName.toLowerCase();
            }
            else if(props.parent_match.name)
            {
                /* try 
                {
                    if(JSON.parse(props.parent_match.name))
                    {
                        let name = JSON.parse(props.parent_match.name)
                        return name[store.state.locale] ? name[store.state.locale].toLowerCase() 
                        : (name['en'] ? name['en'].toLowerCase() : name[Object.keys(name)[0]].toLowerCase());
                    }
                }
                catch(e)
                {
                    return props.parent_match.name.toLowerCase();
                } */
                return props.parent_match.name.toLowerCase();
            }
            return null;
            
        });
        const setFormulator = computed( () => 
        {
            if(props.parent_match.formulator || props.parent_match.formulator_name)
            {
                let name = props.parent_match.formulator?.name || props.parent_match.formulator_name;
                let shortName = name.substring(0, 52);
                if(name.length >= 52) shortName = shortName + '...';
                return shortName;
            }
            return '-';
        });
        const productIsFromGateway = computed( () => 
        {
            let result = 'Crowdsourcing';
            if(props.parent_match.registered && props.parent_match.registered === 'ZDHC Gateway'
                || props.parent_match.source && props.parent_match.source === 'ZDHC Gateway'
                || props.parent_match.fullProduct?.registered && props.parent_match.fullProduct?.registered === 'ZDHC Gateway'
                || props.parent_match.fullProduct?.source && props.parent_match.fullProduct?.source === 'ZDHC Gateway'
            ) result = 'ZDHC';
            return result;
        });

        /**
         *
         * Methods
         *
         */
        const selectProduct = () =>
        {
            emit('setActiveMatch', props.parent_match);
        };

        /**
         *
         * Hooks
         *
         */
        onMounted( () => 
        {
            if(props.parent_match.certifications)
            {
                props.parent_match.certifications.forEach ( c => 
                {
                    if(!certifications.value.some( e => e.certification === c.certification && e.certificationResult === c.certificationResult ))
                    {
                        certifications.value.push({
                            certification: c.certification,
                            certificationResult: c.certification_result
                        });
                    }
                });
            }
            else if(props.parent_match.fullProduct?.productCertifications)
            {
                props.parent_match.fullProduct?.productCertifications.forEach ( c => 
                {
                    if(!certifications.value.some( e => e.certification === c.certification && e.certificationResult === c.certificationResult ))
                    {
                        certifications.value.push({
                            certification: c.certification,
                            certificationResult: c.certificationResult
                        });
                    }
                });
            }
        });
        
        return {
            /* Data */
            store,
            active,
            certifications,
            /* Computed */
            getSelected,
            setName,
            setFormulator,
            productIsFromGateway,
            /* Methods */
            selectProduct,
        } 
    }
}
</script>